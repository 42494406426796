<template>
  <div class="dashboard">
    <div>
      <Menu />
    </div>
    <div class="flex">
      <div class="w-full relative mt-2">
        <div class="text-white font-InterBold text-lg px-6 py-2">
          Invoice details for Game Jams
        </div>
        <div>
          <div
            v-if="data && data.data.length === 0"
            class="bg-nColorTextInput rounded-3xl mx-6 mt-4 mb-4 text-white"
          >
            <div class="text-nColorLightGray text-lg p-10 text-center">
              You have not made any purchases with us yet
            </div>
          </div>
          <div v-else>
            <div v-if="data" class="px-4 pt-2 pb-4">
              <div
                class="bg-nColorButtonText p-4 rounded-2xl border border-nColorBorder text-start mb-3"
                v-for="order in data.data"
                :key="order.invoice_id"
              >
                <div class="grid gap-3 mb-2">
                  <div class="flex justify-between">
                    <div class="text-white font-InterSemiBold text-sm">
                      <span class="font-InterBold">Invoice:</span>
                      {{ order.invoice_id }}
                    </div>
                    <div
                      class="text-nColorOrange font-InterSemiBold text-sm capitalize"
                    >
                      {{ order.plan }}
                    </div>
                  </div>
                  <div class="flex justify-between text-white text-sm">
                    <span class="font-InterBold">Name:</span>
                    <span class="font-InterSemiBold">
                      {{ order.name }}
                    </span>
                  </div>
                  <div class="flex justify-between text-white text-sm">
                    <span class="font-InterBold">Purchase Date:</span>
                    <span class="font-InterSemiBold text-nColorLightGray">
                      {{ order.purchase_date }}
                    </span>
                  </div>
                  <div class="flex justify-between text-white text-sm">
                    <span class="font-InterBold">Amount Paid:</span>
                    <span class="font-InterSemiBold text-white">
                      &#x20b9;{{ order.amount }}
                    </span>
                  </div>
                </div>
                <button
                  class="font-ZuumeMedium rounded-3xl py-0.5 text-xl border border-nColorYellow text-nColorYellow w-full mt-2"
                  @click.once.prevent="downloadInvoice(order.invoice_id)"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Elements/MobileMenu.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import wizard from "@/common/wizard.config";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "PurchaseHistory",
  components: { Menu },
  data() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    return {
      currentDate: `${day}-${month}-${year}`,
      wizard,
      data: null,
    };
  },
  created() {
    ApiService.get(apiResource.getPurchaseHistory, {}).then((data) => {
      this.data = data.data.data;
    });
  },
  mounted() {},
  computed: {},
  methods: {
    goToPricingPage() {
      window.location.href = process.env.VUE_APP_PRICING_URL;
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    downloadInvoice(invoiceId) {
      ApiService.post(apiResource.downloadInvoice, {
        invoice_id: invoiceId,
      })
        .then((data) => {
          var dataResponse = data.data.data;
          const link = document.createElement("a");
          link.href = dataResponse.url;
          link.download = this.getFileNameFromUrl(dataResponse.url); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the click to download the file
          document.body.removeChild(link);
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again later.", {
            timeout: 2000,
          });
        });
    },
    getFileNameFromUrl(url) {
      return url.split("/").pop(); // Extracts the file name from URL
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
</style>
