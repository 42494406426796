<template>
  <div class="dashboard">
    <div class="flex">
      <div>
        <Menu />
      </div>
      <div class="w-full relative">
        <div
          class="flex items-center justify-between px-6 pt-6 pb-2 border-b-2 border-nColorTextInput"
        >
          <div class="font-InterExtraBold text-3xl text-nColorOrange">
            Purchase History
          </div>
          <div id="menu-account">
            <button
              class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
              @click.prevent="signOut()"
            >
              Sign out
            </button>
          </div>
        </div>
        <div class="text-white font-InterBold text-xl px-6 py-2">
          Invoice details for Game Jams
        </div>
        <div class="bg-nColorTextInput rounded-3xl mx-6 mt-4 mb-4 text-white">
          <div class="flex gap-4">
            <div class="grid justify-between p-8">
              <div class="w-2/3">
                <p class="font-ZuumeSemiBold text-6xl">hello!</p>
                <p class="font-InterSemiBold text-nColorLightGray">
                  Invoice details for Game Jams
                </p>
              </div>
            </div>
            <div class="absolute right-60 top-24">
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/billing_invoice_illustration.webp"
                alt="Live Events"
                width="350"
                height="209"
              />
            </div>
          </div>
          <div v-if="data && data.data.length === 0">
            <div class="text-nColorLightGray text-lg p-10 text-center">
              You have not made any purchases with us yet
            </div>
          </div>
          <div v-else>
            <div v-if="data" class="p-6">
              <div class="flex p-4 text-start">
                <div class="w-2/12 text-white font-InterSemiBold text-sm">
                  Invoice Number
                </div>
                <div class="w-2/12 text-white font-InterSemiBold text-sm">
                  Billing Name
                </div>
                <div class="w-1/12 text-white font-InterSemiBold text-sm">
                  Package
                </div>
                <div class="w-3/12 text-white font-InterSemiBold text-sm pl-4">
                  Purchase Date
                </div>
                <div class="w-2/12 text-white font-InterSemiBold text-sm">
                  Amount Paid
                </div>
                <div class="w-2/12"></div>
              </div>
              <div
                class="flex items-center bg-nColorButtonText p-4 rounded-2xl border border-nColorBorder text-start mb-3"
                v-for="order in data.data"
                :key="order"
              >
                <div class="w-2/12 text-white font-InterSemiBold text-sm">
                  {{ order.invoice_id }}
                </div>
                <div class="w-2/12 text-white font-InterSemiBold text-sm">
                  {{ order.name }}
                </div>
                <div
                  class="w-1/12 text-nColorOrange font-InterSemiBold text-sm capitalize"
                >
                  {{ order.plan }}
                </div>
                <div
                  class="w-3/12 text-nColorLightGray font-InterSemiBold text-xs pl-4"
                >
                  {{ order.purchase_date }}
                </div>
                <div class="w-2/12 text-white font-InterSemiBold text-sm">
                  &#x20b9;{{ order.amount }}
                </div>
                <div class="w-2/12">
                  <button
                    class="font-ZuumeMedium px-4 py-0.5 rounded-3xl text-base border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                    @click.once.prevent="downloadInvoice(order.invoice_id)"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/DesktopMenu.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import wizard from "@/common/wizard.config";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "PurchaseHistory",
  components: { Menu },
  data() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    return {
      currentDate: `${day}-${month}-${year}`,
      wizard,
      data: null,
    };
  },
  created() {
    ApiService.get(apiResource.getPurchaseHistory, {}).then((data) => {
      this.data = data.data.data;
    });
  },
  mounted() {},
  computed: {},
  methods: {
    goToPricingPage() {
      window.location.href = process.env.VUE_APP_PRICING_URL;
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    downloadInvoice(invoiceId) {
      ApiService.post(apiResource.downloadInvoice, {
        invoice_id: invoiceId,
      })
        .then((data) => {
          var dataResponse = data.data.data;
          const link = document.createElement("a");
          link.href = dataResponse.url;
          link.download = this.getFileNameFromUrl(dataResponse.url); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the click to download the file
          document.body.removeChild(link);
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again later.", {
            timeout: 2000,
          });
        });
    },
    getFileNameFromUrl(url) {
      return url.split("/").pop(); // Extracts the file name from URL
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
</style>
