export default {
  steps: [
    {
      slug: "step1",
      text: "Name & Add Logo",
    },
    {
      slug: "step2",
      text: "Select Games",
    },
    {
      slug: "step3",
      text: "Summary",
    },
    {
      slug: "step4",
      text: "Download",
    },
  ],
};
